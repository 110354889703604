export const palette = {
	yellow: {
		y50: '#fff8e6',
		y100: '#ff39b0',
		y200: '#ffde8a',
		y300: '#ffcf54',
		y400: '#ffc633',
		y500: '#ffb800',
		y600: '#e8a700',
		y700: '#b58300',
		y800: '#8c6500',
		y900: '#6b4d00',
		y1000: '#3e2d00',
	},
	green: {
		g50: '#f2fbf3',
		g100: '#d7f2d8',
		g200: '#c4ebc6',
		g300: '#a9e2ab',
		g400: '#99dd9b',
		g500: '#7fd482',
		g600: '#74c176',
		g700: '#5a975c',
		g800: '#467548',
		g900: '#355937',
	},
	red: {
		r50: '#fde6e6',
		r100: '#f9b0b0',
		r200: '#f68a8a',
		r300: '#f25454',
		r400: '#ef3333',
		r500: '#eb0000',
		r600: '#d60000',
		r700: '#a70000',
		r800: '#810000',
		r900: '#630000',
	},
	orange: {
		o50: '#fff0e8',
		o100: '#ffd1b7',
		o200: '#ffbb95',
		o300: '#ff9c64',
		o400: '#ff8946',
		o500: '#ff6b18',
		o600: '#e86116',
		o700: '#b54c11',
		o800: '#8c3b0d',
		o900: '#6b2d0a',
	},
	neutral: {
		n0: '#ffffff',
		n10: '#fafafa',
		n20: '#f5f5f5',
		n30: '#ececeb',
		n40: '#e0e0df',
		n50: '#c4c3c2',
		n60: '#b5b4b3',
		n70: '#a9a8a6',
		n80: '#9b9a98',
		n90: '#8d8b89',
		n100: '#7e7d7a',
		n200: '#706e6b',
		n300: '#62605d',
		n400: '#565450',
		n500: '#484542',
		n600: '#3c3935',
		n700: '#2B2A28',
		n800: '#1d1a15',
		n900: '#14120F',
		n1000: '#110E09',
		n1100: '#0D0B08',
	},
	gradient: {
		gold: 'linear-gradient(180deg, #BE9461 0%, #9C6C3C 100%)',
	},
	alpha: {
		white1: 'rgb(255,255,255,0.01)',
		white5: 'rgb(255,255,255,0.5)',
		white10: 'rgb(255,255,255,0.1)',
		red10: 'rgb(241,43,43,0.1)',
		red15: 'rgb(241,43,43,0.15)',
		red5: 'rgb(241,43,43,0.05)',
		green20: 'rgba(127,212,130,0.2)',
		green10: 'rgba(127,212,130,0.1)',
		green5: 'rgba(127,212,130,0.05)',
		lightButton: 'linear-gradient(180deg, #EEEEEE 0%, #E0E0E0 100%)',
		lightButtonHover: 'linear-gradient(180deg, #E6E6E6 0%, #CCCCCC 100%)',
		darkButton: 'linear-gradient(180deg, #282727 0%, #191818 100%)',
		darkButtonHover: 'linear-gradient(180deg, #383838 0%, #1E1E1E 100%)',
	},
};

const common = {
	primaryWhite: '#ECE8E3',
	primaryGold: '#C9975B',
	primaryRed: '#EF6868',
	primaryGreen: '#7FD482',
	primaryGray: '#B1B1B1',
	secondaryGray: '#515151',
	neautralGray: '#A9A8A6',
	tertiaryGray: '#999999',
	secondaryGold: '#E4B378',
	primaryYellow: '#FFB800',
	black: '#171002',
	darkYellow: '#3E2D00',
	dark: {
		white: '#ECE8E3',
		yellow: '#FFB800',
		red: '#EF6868',
		green: '#7FD482',
		background: '#131312',
		surfaceFill: '#181818',
		title: '#B1B1B1',
		border: '1px solid rgba(255, 255, 255, 0.12)',
		outlineBorder: '1px solid rgba(255, 255, 255, 0.12)',
		orange: '#FF6B19',
		black: '#171002',
		gray: '#787878',
		gray2: '#3F3F3F',
		rewardTitle: '#ECE8E3',
	},
	light: {
		white: '#F2F2F2',
		yellow: '#6A3300',
		background: '#F2F2F2',
		surfaceFill: '#F2F2F2', // TODO: Update the light theme
		title: '#171002',
		border: '1px solid rgba(0,0,0,0.1)',
		outlineBorder: '1px solid rgba(0,0,0,0.1)',
		red: '#A80300',
		green: '#1D5D1F',
		orange: '#FF6B19',
		black: '#171002',
		gray: '#515151',
		gray2: '#D2D2D2', // TODO: Update once added to designs
		rewardTitle: '#171002',
	},
	palette,
};

export default common;
